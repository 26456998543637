<template>
  <div>
    <mdb-row class="justify-content-md-center">
      <mdb-col col="6">
        <!--etunimi, sukunimi, sähköpostiosoite, osoite, postinumero, postitoimipaikka, puhelin, ja nappi, rekisteröidy.-->
        <mdb-input
          type="text"
          outline
          inputClass="z-depth-1 p-3"
          v-model="senderMessageLocal"
          @change="senderMessageChanged"
          :label="$t('basicform.first_name')"
        />
      </mdb-col>
      <mdb-col col="6">
        <mdb-input
          type="text"
          outline
          inputClass="z-depth-1 p-3"
          v-model="senderMessageLocal"
          @change="senderMessageChanged"
          :label="$t('basicform.last_name')"
        />
      </mdb-col>
      <mdb-col col="12">
        <mdb-input
          type="text"
          outline
          inputClass="z-depth-1 p-3"
          v-model="senderMessageLocal"
          @change="senderMessageChanged"
          :label="$t('basicform.email')"
        />
      </mdb-col>
      <mdb-col col="12">
        <mdb-input
          type="text"
          outline
          inputClass="z-depth-1 p-3"
          v-model="senderMessageLocal"
          @change="senderMessageChanged"
          :label="$t('basicform.address')"
        />
      </mdb-col>
      <mdb-col col="6">
        <mdb-input
          type="number"
          outline
          inputClass="z-depth-1 p-3"
          v-model="senderMessageLocal"
          @change="senderMessageChanged"
          :label="$t('basicform.postalcode')"
        />
      </mdb-col>
      <mdb-col col="6">
        <mdb-input
          type="text"
          outline
          inputClass="z-depth-1 p-3"
          v-model="senderMessageLocal"
          @change="senderMessageChanged"
          :label="$t('basicform.postoffice')"
        />
      </mdb-col>
      <mdb-col col="12">
        <mdb-input
          type="text"
          outline
          inputClass="z-depth-1 p-3"
          v-model="senderMessageLocal"
          @change="senderMessageChanged"
          :label="$t('basicform.phonenumber')"
        />
      </mdb-col>
      <mdb-col col="12">
        <mdb-btn class="grey darken-1 mt-3 mb-3" block @click="handleSend">{{
          $t("basicform.send")
        }}</mdb-btn>
      </mdb-col>
    </mdb-row>
  </div>
</template>
<script>
import { mdbRow, mdbCol, mdbInput, mdbBtn } from "mdbvue";
import helper from "@/mixins/helper";
import api from "@/mixins/api";
export default {
  name: "KotipizzaRecommended",
  mixins: [helper, api],
  components: {
    mdbRow,
    mdbCol,
    mdbInput,
    mdbBtn,
  },
  props: {
    vendorData: Object,
  },
  data() {
    return {
      transactionData: "",
      localVendorData: this.vendorData,
      senderMessageLocal: "",
    };
  },
  methods: {
    senderMessageChanged() {},
    handleSend() {
      this.$notify.success({
        message: this.$t("basicform.form_sent"),
        position: "top right",
        timeOut: this.$store.state.notifytimeoutshort,
      });
      setTimeout(
        function () {
          this.$router.push({
            path: "/thankyou",
          });
        }.bind(this),
        1000
      );
    },
  },
  mounted() {},
};
</script>
<style lang=""></style>
